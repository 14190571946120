import request from '@/utils/apiRequest';

export function auth(user) {
  return request({
    url: '/auth/login',
    method: 'post',
    data: user
  });
}

export function getUserById(id) {
  return request({
    url: `/user/${id}`,
    method: 'get'
  });
}

export function getUserByEmail(email) {
  return request({
    url: `/user/email/${email}`,
    method: 'get'
  });
}

export function getRtcToken(payload) {
  return request({
    url: '/auth/agora/rtc/token',
    method: 'get',
    params: payload
  });
}

export function getRtmToken(payload) {
  return request({
    url: '/auth/agora/rtm/token',
    method: 'get',
    params: payload
  });
}

export function logout() {
  return request({
    url: '/user/logout',
    method: 'post'
  });
}

export function register(payload) {
  return request({
    url: '/auth/register',
    method: 'post',
    data: payload
  });
}

export function reset(payload) {
  return request({
    url: `/auth/reset-password`,
    method: 'post',
    data: payload
  });
}

export function isTokenValid(payload) {
  return request({
    url: `/auth/token/validate`,
    method: 'post',
    data: payload
  });
}

export function update(payload) {
  return request({
    url: `/user/${payload._id}`,
    method: 'patch',
    data: payload
  });
}

export function updatePassword(payload) {
  return request({
    url: `/user/password/${payload._id}`,
    method: 'post',
    data: payload
  });
}

export function getVersion(payload) {
  return request({
    url: `/auth/version`,
    method: 'get',
    params: payload
  });
}

export function pushVersion(payload) {
  return request({
    url: `/auth/version`,
    method: 'post',
    data: payload
  });
}

export function remove(payload) {
  return request({
    url: `/user/${payload._id}`,
    method: 'delete'
  });
}
