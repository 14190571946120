import {
  list,
  get,
  create,
  update,
  remove,
  getCompanyPeople
} from '@/api/company';

const company = {
  state: {
    companies: []
  },

  mutations: {
    SET_COMPANIES: (state, companies) => {
      state.companies = companies;
    }
  },

  getters: {
    getCompanies(state) {
      return state.companies;
    }
  },

  actions: {
    listCompanies({ commit }, query) {
      return new Promise((resolve, reject) => {
        list(query)
          .then((response) => {
            commit('SET_COMPANIES', response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCompany({ commit }, companyId) {
      return new Promise((resolve, reject) => {
        get(companyId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getCompanyPeople({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getCompanyPeople(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    createCompany({ commit }, payload) {
      return new Promise((resolve, reject) => {
        create(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    updateCompany({ commit }, payload) {
      return new Promise((resolve, reject) => {
        update(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteCompany({ commit }, payload) {
      return new Promise((resolve, reject) => {
        remove(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default company;
