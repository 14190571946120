import request from '@/utils/apiRequest';

export function list(query) {
  return request({
    url: `/company`,
    method: 'get',
    query
  });
}

export function get(companyId) {
  return request({
    url: `/company/${companyId}`,
    method: 'get'
  });
}

export function getCompanyPeople({ companyId, query }) {
  return request({
    url: `/company/${companyId}/people`,
    method: 'get',
    query
  });
}

export function create(payload) {
  return request({
    url: '/company',
    method: 'post',
    data: payload
  });
}

export function update(payload) {
  return request({
    url: `/company/${payload._id}`,
    method: 'patch',
    data: payload
  });
}

export function remove(payload) {
  return request({
    url: `/company/${payload._id}`,
    method: 'delete'
  });
}
