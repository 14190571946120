import { register, getUserById, update, remove } from '@/api/user';

const people = {
  actions: {
    getPeopleById({ commit, state }, userId) {
      return new Promise((resolve, reject) => {
        getUserById(userId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    addPeople({ commit }, payload) {
      return new Promise((resolve, reject) => {
        register(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updatePeople({ commit }, payload) {
      return new Promise((resolve, reject) => {
        update(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deletePeople({ commit }, payload) {
      return new Promise((resolve, reject) => {
        remove(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default people;
