import Vue from 'vue';
import Router from 'vue-router';
import { publicRoute, protectedRoute } from './config';
import { getToken } from '@/utils/auth';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';
import store from '../store/index';
import { decode } from 'jwt-simple';

const routes = publicRoute.concat(protectedRoute);

Vue.use(Router);
const router = new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  routes: routes
});

// router gards
const whiteList = ['/auth/login', '/auth/reset', '/auth/reset-password'];
router.beforeEach((to, from, next) => {
  NProgress.start();
  // auth route is authenticated
  if (getToken()) {
    const token = getToken().replace('Bearer ', '').trim();
    if (!store.getters.getUser.email) {
      const tokenPayload = decode(token, process.env.VUE_APP_JWT_SECRET);
      store
        .dispatch('getUserById', tokenPayload.sub)
        .then((r) => console.log('local user updated.'));
    }
    if (to.path === '/auth/login') {
      next({ path: '/' });
      NProgress.done();
    } else {
      next();
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next({ path: '/auth/login', query: { redirect: to.path } });
      NProgress.done();
    }
  }
});

router.afterEach((to, from) => {
  NProgress.done();
  if (from.fullPath.match(/^\/show\/([a-z0-9]+)\/view/i)) {
    location.reload();
  }
});

export default router;
