import { AuthLayout, DefaultLayout } from '@/components/layouts';

export const publicRoute = [
  {
    path: '*',
    component: () => import('@/views/error/NotFound.vue')
  },
  {
    path: '/auth',
    component: AuthLayout,
    meta: { title: 'Login' },
    redirect: '/auth/login',
    hidden: true,
    children: [
      {
        path: 'login',
        name: 'login',
        meta: { title: 'Login' },
        component: () => import('@/views/auth/Login.vue')
      },
      {
        path: 'reset',
        name: 'reset',
        meta: { title: 'Reset Password' },
        component: () => import('@/views/auth/RequestResetPassword.vue')
      },
      {
        path: 'reset-password',
        name: 'reset-password',
        meta: { title: 'Reset Password' },
        component: () => import('@/views/auth/RestPassword.vue')
      }
    ]
  },

  {
    path: '/404',
    name: '404',
    meta: { title: 'Not Found' },
    component: () => import('@/views/error/NotFound.vue')
  },

  {
    path: '/mobile',
    name: 'mobile',
    meta: { title: 'Mobile access not supported' },
    component: () => import('@/views/error/Mobile.vue')
  },

  {
    path: '/500',
    name: '500',
    meta: { title: 'Server Error' },
    component: () => import('@/views/error/Error.vue')
  }
];

export const protectedRoute = [
  {
    path: '/',
    component: DefaultLayout,
    meta: { title: 'Home', group: 'apps', icon: '' },
    redirect: '/auth/login',
    children: [
      {
        path: '/',
        name: 'Home',
        meta: { title: 'Projects', group: 'apps' },
        component: () => import('@/views/home/View.vue')
      },
      {
        path: 'project/:id',
        meta: { title: 'Projects', group: 'apps' },
        component: () => import('@/views/project/View.vue'),
        children: [
          {
            path: '',
            name: 'Project',
            meta: { title: 'Project', group: 'apps', ignore: true },
            component: () => import('@/views/project/Project.vue')
          },
          {
            path: 'volumes',
            name: 'Volumes',
            meta: { title: 'Volumes', group: 'apps' },
            component: () => import('@/views/project/Volume.vue')
          }
        ]
      },
      {
        path: 'company',
        meta: { title: 'Empresas', group: 'apps' },
        component: () => import('@/views/company/View.vue'),
        children: [
          {
            path: '',
            name: 'Company',
            meta: { title: 'Empresas', group: 'apps', ignore: true },
            component: () => import('@/views/company/Company.vue')
          },
          {
            path: 'create',
            name: 'Form',
            meta: { title: 'Empresa', group: 'apps' },
            component: () => import('@/views/company/Form.vue')
          },
          {
            path: ':id',
            name: 'Form',
            meta: { title: 'Editar empresa', group: 'apps' },
            component: () => import('@/views/company/Form.vue')
          },
          {
            path: ':id/people',
            meta: { title: 'People', group: 'apps' },
            component: () => import('@/views/people/View.vue'),
            children: [
              {
                path: '',
                name: 'People',
                meta: { title: 'Pessoas', group: 'apps', ignore: true },
                component: () => import('@/views/people/People.vue')
              },
              {
                path: 'create',
                name: 'Create People',
                meta: { title: 'Criar Pessoa', group: 'apps' },
                component: () => import('@/views/people/Form.vue')
              },
              {
                path: ':peopleId',
                name: 'Edit People',
                meta: { title: 'Editar Pessoa', group: 'apps' },
                component: () => import('@/views/people/Form.vue')
              }
            ]
          }
        ]
      },
      {
        path: 'settings/edit',
        name: 'EditSettings',
        meta: { title: 'Settings', group: 'apps' },
        component: () => import('@/views/settings/Edit.vue')
      },
      {
        path: 'import',
        name: 'ImportCSV',
        meta: { title: 'Import CSV', group: 'apps' },
        component: () => import('@/views/import/ImportCSV.vue')
      }
    ]
  }
];
