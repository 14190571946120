import { create, remove } from '@/api/group';

const group = {
  state: {
    companies: []
  },

  mutations: {},

  getters: {
    getCompanies(state) {
      return state.companies;
    }
  },

  actions: {
    createGroup({ commit }, payload) {
      return new Promise((resolve, reject) => {
        create(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    removeGroup({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        remove(projectId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default group;
