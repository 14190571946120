<template>
  <v-dialog :persistent="isPersistent" v-model="show" width="500">
    <v-card>
      <v-card-text class="dialog_message">
        {{ message }}
      </v-card-text>
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn
          color="primary"
          text
          class="ma-2 white--text"
          @click="dismissAction"
        >
          {{ dismissButtonText }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'message-dialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    isPersistent: {
      type: Boolean,
      default: true
    },
    message: { type: String },
    title: { type: String, default: 'title' },
    dismissAction: { type: Function },
    dismissButtonText: {
      type: String,
      default: 'OK'
    }
  },
  computed: {},
  data() {
    return {};
  },
  methods: {
    confirm() {
      this.confirmationAction();
    }
  }
};
</script>

<style scoped>
.btn_red {
  border-radius: 8px;
  height: 40px !important;
  line-height: 46px;
  font-size: 14px;
  text-transform: uppercase;
  display: block;
  background: linear-gradient(to right, #e52d27 0%, #b31217 51%, #e52d27 100%);
  background-size: 200% auto;
}
.btn_red:hover {
  background-position: right center;
}
.v-dialog .theme--light.v-sheet {
  background-color: #fff !important;
}
::v-deep .v-dialog {
  border-radius: 15px;
}
.v-dialog .v-card {
  padding-top: 20px;
}
.dialog_message {
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  max-width: 340px;
  margin: 0 auto;
}
.v-card__actions {
  padding: 10px 0 30px;
  justify-content: center;
}
</style>
