import request from '@/utils/apiRequest';

export function list(projectId) {
  return request({
    url: `/piece/project/${projectId}`,
    method: 'get'
  });
}

export function getPieceByProject(pieceId, projectId) {
  return request({
    url: `/piece/${pieceId}/project/${projectId}`,
    method: 'get'
  });
}

export function getPiecesByOrder(order) {
  return request({
    url: `/piece/order/${order}`,
    method: 'get'
  });
}

export function create(payload) {
  return request({
    url: '/piece',
    method: 'post',
    data: payload
  });
}

export function update(payload) {
  return request({
    url: `/piece/${payload._id}`,
    method: 'patch',
    data: payload
  });
}
