import request from '@/utils/apiRequest';

export function get(projectId) {
  return request({
    url: `/project/${projectId}`,
    method: 'get'
  });
}

export function list(query) {
  return request({
    url: `/project`,
    method: 'get',
    query
  });
}

export function create(payload) {
  return request({
    url: '/project',
    method: 'post',
    data: payload
  });
}

export function update(payload) {
  return request({
    url: `/project/${payload._id}`,
    method: 'patch',
    data: payload
  });
}

export function deliver(projectId) {
  return request({
    url: `/project/deliver/${projectId}`,
    method: 'post'
  });
}

export function archive(projectId) {
  return request({
    url: `/project/archive/${projectId}`,
    method: 'post'
  });
}

export function remove(projectId) {
  return request({
    url: `/project/${projectId}`,
    method: 'delete'
  });
}
