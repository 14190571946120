module.exports = Object.freeze({
  /**
   * STATUS
   */
  STATUS_READY_FOR_PACKAGE: 'READY_FOR_PACKAGE',
  STATUS_PACKED: 'PACKED',
  STATUS_ON_BOARD: 'ON_BOARD',
  STATUS_DELIVERED: 'DELIVERED',
  STATUS_CREATED: 'CREATED',
  STATUS_USED: 'USED',
  STATUS_ARCHIVED: 'ARCHIVED',

  /**
   * ROLES
   */
  ROLE_USER: 'user',
  ROLE_SUPER_USER: 'superuser',
  ROLE_ADMIN: 'admin'
});
