import axios from 'axios';
import store from '../store/index';
import { getToken } from '@/utils/auth';

const service = axios.create({
  baseURL: process.env.VUE_APP_API_URL || 'https://api-safemod.herokuapp.com/api/v1',
  timeout: 5000
});

service.interceptors.request.use(
  config => {
    config.headers['Authorization'] = getToken();
    return config;
  },
  error => {
    console.log(error);
    Promise.reject(error);
  }
);
/* eslint-disable prefer-promise-reject-errors */

service.interceptors.response.use(
  response => {
    if (!response.status > 200 && !response.status < 399) {
      if (response.status === 403 || response.status === 401) {
        store.dispatch('fedLogOut').then(() => {
          location.reload();
        });
      }
      return Promise.reject('error');
    } else {
      return response.data;
    }
  },
  ({ response }) => {
    if (response.status === 403 || response.status === 401) {
      if (response.config.url.indexOf('auth') === -1) {
        store.dispatch('fedLogOut').then(() => {
          location.reload();
        });
      }
    }
    return Promise.reject(response);
  }
);

export default service;
