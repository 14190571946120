<template>
  <v-toolbar fixed dark class="elevation-0 white_bg" app>
    <v-container>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon @click="handleFullScreen()">
          <v-icon>fullscreen</v-icon>
        </v-btn>
        <v-menu
          offset-y
          origin="center center"
          :nudge-bottom="10"
          transition="scale-transition"
        >
          <v-btn icon large flat slot="activator">
            <v-avatar size="30px">
              <v-img :src="this.user.picture" lazy-src="static/avatar.png" />
            </v-avatar>
          </v-btn>
          <v-list class="pa-0">
            <v-list-tile
              v-for="(item, index) in items"
              :to="!item.href ? { name: item.name } : null"
              :href="item.href"
              @click="item.click"
              ripple="ripple"
              :disabled="item.disabled"
              :target="item.target"
              rel="noopener"
              :key="index"
            >
              <v-list-tile-action v-if="item.icon">
                <v-icon>{{ item.icon }}</v-icon>
              </v-list-tile-action>
              <v-list-tile-content>
                <v-list-tile-title>{{ item.title }}</v-list-tile-title>
              </v-list-tile-content>
            </v-list-tile>
          </v-list>
        </v-menu>
      </v-toolbar-items>
      <message-dialog
        :show.sync="showAbout"
        :dismiss-action="
          () => {
            this.showAbout = false;
          }
        "
        :message="version"
        title="About Safemod"
      >
      </message-dialog>
    </v-container>
    <message-dialog
      :show.sync="showAbout"
      :dismiss-action="
        () => {
          this.showAbout = false;
        }
      "
      :message="version"
      title="About Safemod"
    >
    </message-dialog>
  </v-toolbar>
</template>
<script>
/* eslint-disable */

import Util from '@/utils';
import { mapActions, mapGetters } from 'vuex';
import MessageDialog from '@/components/dialogs/MessageDialog';

export default {
  name: 'AppToolbar',
  components: {
    MessageDialog
  },
  data() {
    return {
      user: {},
      showAbout: false,
      version: '',
      items: [
        {
          icon: 'account_circle',
          href: '#',
          title: 'Profile',
          click: this.handleProfile
        },
        {
          icon: 'info',
          href: '#',
          title: 'About',
          click: this.handleAbout
        },
        {
          icon: 'fullscreen_exit',
          href: '#',
          title: 'Logout',
          click: this.handleLogout
        }
      ]
    };
  },
  computed: {
    ...mapGetters(['getUser']),
    toolbarColor() {
      return this.$vuetify.options.extra.mainNav;
    },
    isSuperUser() {
      return this.user.role === 'admin';
    }
  },
  async mounted() {
    await this._handleVersion();
    this.user = this.getUser;
    this.version = `Version: ${process.env.VUE_APP_VERSION}`;
  },
  methods: {
    ...mapActions({
      logout: 'fedLogOut',
      _handleVersion: 'handleVersion'
    }),
    handleDrawerToggle() {
      this.$emit('side-icon-click');
    },
    handleFullScreen() {
      Util.toggleFullScreen();
    },
    handleLogout() {
      //handle logout
      this.logout().then(() => {
        this.$router.push('/auth/login');
      });
    },
    handleSetting() {
      this.$router.push('/settings/edit');
    },
    handleProfile() {
      this.$router.push('/settings/edit');
    },
    handleAbout() {
      this.showAbout = true;
    },
    _navigateToAddShow() {
      this.$router.push(`/show/add`);
    }
  }
};
</script>

<style lang="css" scoped>
.white_bg.theme--light.v-toolbar {
  background: #ffffff;
}

nav.white_bg .v-toolbar__content {
  max-width: 1200px !important;
  margin: 0 auto !important;
}

.container {
  max-width: 1200px;
  margin: 0 auto !important;
  display: flex;
  align-items: center;
}

.logo.v-btn {
  background: transparent !important;
  box-shadow: none !important;
}

.logo.v-btn:before {
  content: none;
}

.logo.v-btn .v-ripple__container {
  display: none !important;
  opacity: 0 !important;
}

:hover {
  background-position: right center;
}
.v-list__tile__action {
  min-width: 35px;
}
</style>
