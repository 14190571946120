import Vue from 'vue';
import './plugins/vuetify';
import App from './App.vue';
import router from './router/';
import store from './store/index';
import './registerServiceWorker';
import 'roboto-fontface/css/roboto/roboto-fontface.css';
import 'font-awesome/css/font-awesome.css';
import vueMoment from 'vue-moment';
import VueSocketIO from 'vue-socket.io';
import io from 'socket.io-client';
import firebase from 'firebase';
import './stylus/main.styl';
import VueHtmlToPaper from 'vue-html-to-paper';
firebase.initializeApp({
  apiKey: 'AIzaSyDcnz2hPvVPWQqqzC27n4JTag-cGlGB4O0',
  authDomain: 'safemod-b2390.firebaseapp.com',
  projectId: 'safemod-b2390',
  storageBucket: 'safemod-b2390.appspot.com',
  messagingSenderId: '108967980153',
  appId: '1:108967980153:web:c43b75ba36d1213e52b8a8'
});

// Vue.use(VueSocketIO, io(process.env.VUE_APP_API_URL));

const options = {
  // path: '/shows',
  transport: ['websocket']
};

Vue.use(
  new VueSocketIO({
    debug: true,
    connection: io(process.env.VUE_APP_API_HOST, options),
    vuex: {
      store,
      actionPrefix: 'SOCKET_',
      mutationPrefix: 'SOCKET_'
    }
  })
);

Vue.config.productionTip = false;
Vue.use(vueMoment);
Vue.use(VueHtmlToPaper, {
  styles: ['/static/css/print.css']
});

new Vue({
  router,
  store,
  render: (h) => h(App)
}).$mount('#app');
