import request from '@/utils/apiRequest';

export function create(payload) {
  return request({
    url: '/group',
    method: 'post',
    data: payload
  });
}

export function remove(projectId) {
  return request({
    url: `/group/${projectId}`,
    method: 'delete'
  });
}
