import constants from '@/utils/constants';

const Menu = [
  {
    name: 'Companies',
    title: 'Empresas',
    component: 'Company',
    icon: 'business',
    href: '#/company',
    roles: [constants.ROLE_SUPER_USER]
  },
  {
    name: 'People',
    title: 'Pessoas',
    component: 'People',
    icon: 'people',
    href: '#/company/{{companyId}}/people',
    roles: [constants.ROLE_ADMIN]
  },
  {
    name: 'Projects',
    title: 'Projects',
    component: 'Home',
    icon: 'list',
    href: '#/',
    roles: [
      constants.ROLE_USER,
      constants.ROLE_ADMIN,
      constants.ROLE_SUPER_USER
    ]
  },
  {
    name: 'Import',
    title: 'Import CSV',
    component: 'ImportCSV',
    icon: 'add',
    href: '#/import',
    roles: [
      constants.ROLE_USER,
      constants.ROLE_ADMIN,
      constants.ROLE_SUPER_USER
    ]
  }
];
Menu.forEach((item) => {
  if (item.items) {
    item.items.sort((x, y) => {
      const textA = x.title.toUpperCase();
      const textB = y.title.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
  }
});

export function getMenu(user) {
  const filtered = Menu.filter((item) => item.roles.includes(user.role));
  return filtered.map((item) => {
    if (item.href.includes('{{companyId}}')) {
      item.href = item.href.replace('{{companyId}}', user.company);
    }
    return item;
  });
}
