import Vue from 'vue';
import Vuex from 'vuex';
import app from './module/app';
import user from './module/user';
import people from './module/people';
import piece from './module/piece';
import project from './module/project';
import volume from './module/volume';
import group from './module/group';
import company from './module/company';
import version from './module/version';
import getters from './getters';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: ['user']
    })
  ],
  modules: {
    app,
    company,
    group,
    people,
    piece,
    project,
    user,
    version,
    volume
  },
  getters
});

export default store;
