import { create, list, update } from '@/api/piece';

const piece = {
  state: {
    pieces: [],
    total: 0
  },

  mutations: {
    SET_PIECES: (state, pieces) => {
      state.pieces = pieces;
    }
  },

  getters: {
    getPieces(state) {
      return state.pieces;
    }
  },

  actions: {
    listPieces({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        list(projectId)
          .then((response) => {
            commit('SET_PIECES', response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    importPieces({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        create(projectId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updatePiece({ commit }, payload) {
      return new Promise((resolve, reject) => {
        update(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default piece;
