import { getVersion, pushVersion } from '@/api/user';

const semver = require('semver');

const version = {
  actions: {
    async handleVersion({ commit, state }) {
      const currentStoredVersion = await getVersion({
        source: process.env.VUE_APP_ENV
      });
      if (
        !currentStoredVersion ||
        !currentStoredVersion['version'] ||
        semver.lt(currentStoredVersion['version'], process.env.VUE_APP_VERSION)
      ) {
        await pushVersion({
          version: process.env.VUE_APP_VERSION,
          source: process.env.VUE_APP_ENV
        });
      } else if (
        semver.gt(currentStoredVersion['version'], process.env.VUE_APP_VERSION)
      ) {
        if ('caches' in window) {
          caches.keys().then((names) => {
            names.forEach(async (name) => {
              await caches.delete(name);
            });
          });
        }
        window.location.reload();
      }
    }
  }
};

export default version;
