import request from '@/utils/apiRequest';

export function getVolume(id) {
  return request({
    url: `/volume/${id}`,
    method: 'get'
  });
}

export function list(projectId) {
  return request({
    url: `/volume/project/${projectId}`,
    method: 'get'
  });
}

export function getVolumeByProject({ volumeId, projectId }) {
  return request({
    url: `/volume/${volumeId}/project/${projectId}`,
    method: 'get'
  });
}

export function create(payload) {
  return request({
    url: '/volume',
    method: 'post',
    data: payload
  });
}

export function ship(payload) {
  return request({
    url: `/volume/ship/${payload._id}`,
    method: 'post',
    data: payload
  });
}

export function deliver(payload) {
  return request({
    url: `/volume/deliver/${payload._id}`,
    method: 'post',
    data: payload
  });
}

export function update(payload) {
  return request({
    url: `/volume/${payload._id}`,
    method: 'patch',
    data: payload
  });
}
