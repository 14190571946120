<template>
  <v-container>
    <v-layout row class="align-center layout px-4 pt-4 app--page-header">
      <div class="page-header-left">
        <h3 class="pr-3">{{ $route.meta.title || '' }}</h3>
      </div>
      <v-breadcrumbs divider="-" :items="breadcrumbs">
        <template v-slot:item="props">
          <a
            @click="_navigateTo(props.item)"
            :class="[props.item.disabled && 'disabled']"
            >{{ props.item.text }}</a
          >
        </template>
      </v-breadcrumbs>
      <v-spacer></v-spacer>
    </v-layout>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      title: 'Home',
      breadcrumbs: []
    };
  },
  watch: {
    '$route.path': function () {
      this.computeBreadcrumbs();
    }
  },
  created() {
    this.computeBreadcrumbs();
  },
  methods: {
    computeBreadcrumbs() {
      const appends = this.$route.matched.map((item) => {
        return {
          text: item.meta.title || '',
          href: item.path || '/',
          disabled: item.path === this.$route.path,
          ignore: item.meta.ignore || false
        };
      });
      this.breadcrumbs = appends.filter((item) => !item.ignore);
    },
    _navigateTo(item) {
      if (item.href !== this.$route.path) {
        this.$router.push({ path: item.href });
      }
    }
  }
};
</script>
<style lang="css" scoped>
.app--page-header,
.v-breadcrumbs a {
  color: #000 !important;
}
.v-breadcrumbs a.disabled {
  opacity: 0.8;
}
</style>
