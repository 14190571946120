import {
  create,
  list,
  ship,
  deliver,
  getVolumeByProject,
  getVolume
} from '@/api/volume';

const volume = {
  state: {
    volumes: []
  },

  mutations: {
    SET_VOLUMES: (state, volumes) => {
      state.volumes = volumes;
    }
  },

  getters: {
    getVolumes(state) {
      return state.volumes;
    }
  },

  actions: {
    getVolume({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getVolume(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    listVolumes({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        list(projectId)
          .then((response) => {
            commit('SET_VOLUMES', response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getVolumeByProject({ commit }, payload) {
      return new Promise((resolve, reject) => {
        getVolumeByProject(payload)
          .then((response) => {
            commit('SET_VOLUMES', response);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    createVolume({ commit }, payload) {
      return new Promise((resolve, reject) => {
        create(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    shipVolume({ commit }, payload) {
      return new Promise((resolve, reject) => {
        ship(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    deliverVolume({ commit }, payload) {
      return new Promise((resolve, reject) => {
        deliver(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default volume;
