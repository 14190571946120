import {
  auth,
  logout,
  register,
  getUserById,
  getUserByEmail,
  update,
  reset,
  remove,
  isTokenValid,
  updatePassword
} from '@/api/user';
import { getToken, setToken, removeToken } from '@/utils/auth';

const user = {
  state: {
    token: getToken(),
    user: {}
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token;
    },
    SET_USER: (state, user) => {
      state.user = user;
    }
  },
  getters: {
    getUser(state) {
      return state.user;
    }
  },

  actions: {
    getUserById({ commit, state }, userId) {
      return new Promise((resolve, reject) => {
        getUserById(userId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    getUserByEmail({ commit, state }, userId) {
      return new Promise((resolve, reject) => {
        getUserByEmail(userId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    login({ commit }, userInfo) {
      userInfo.email = userInfo.email.trim();
      return new Promise((resolve, reject) => {
        auth(userInfo)
          .then((response) => {
            setToken(
              `${response.token.tokenType}  ${response.token.accessToken}`
            );
            commit(
              'SET_TOKEN',
              `${response.token.tokenType} ${response.token.accessToken}`
            );
            commit('SET_USER', response.user);
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(error);
          });
      });
    },

    register({ commit }, payload) {
      return new Promise((resolve, reject) => {
        register(payload)
          .then((response) => {
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updateUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        update(payload)
          .then((response) => {
            commit('SET_USER', response);
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    reset({ commit }, payload) {
      return new Promise((resolve, reject) => {
        reset(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    isTokenValid({ commit }, payload) {
      return new Promise((resolve, reject) => {
        isTokenValid(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    updatePassword({ commit }, payload) {
      return new Promise((resolve, reject) => {
        updatePassword(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    logOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token)
          .then(() => {
            commit('SET_TOKEN', '');
            commit('SET_ROLES', []);
            removeToken();
            resolve();
          })
          .catch((error) => {
            reject(error);
          });
      });
    },

    fedLogOut({ commit }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '');
        removeToken();
        resolve();
      });
    },

    deleteUser({ commit }, payload) {
      return new Promise((resolve, reject) => {
        remove(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default user;
