import { get, archive, deliver, list, remove } from '@/api/project';

const project = {
  state: {
    projects: [],
    total: 0
  },

  mutations: {
    SET_PROJECTS: (state, projects) => {
      state.projects = projects;
    },
    SET_TOTAL: (state, total) => {
      state.total = total;
    }
  },

  getters: {
    getProjects(state) {
      return state.projects;
    },
    getTotal(state) {
      return state.total;
    }
  },

  actions: {
    getProject({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        get(projectId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    listProjects({ commit }, query) {
      return new Promise((resolve, reject) => {
        list(query)
          .then((response) => {
            commit('SET_PROJECTS', response.projects);
            commit('SET_TOTAL', response.total);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deliverProject({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        deliver(projectId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    archiveProject({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        archive(projectId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    deleteProject({ commit }, projectId) {
      return new Promise((resolve, reject) => {
        remove(projectId)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }
};

export default project;
